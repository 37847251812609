import {
  Autocomplete,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { Formik, getIn } from 'formik';
import * as Yup from 'yup';
import { encode as base64_encode } from 'base-64';
import toast from 'react-hot-toast';

import {
  DateTimePicker,
  DesktopTimePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { PrimaryButton, SecondaryButton } from '@/aggregator/ui/inputs/Button';
import { scheduleTask } from '@/aggregator/services/shared';
import { TaskType } from './TaskType';
import { subHours } from 'date-fns';
import { CarrierType } from '../carriers/types';
import * as auth from '@/aggregator/config/auth';

interface Iprops {
  handleClose: () => void;
  carriers: CarrierType[];
  merchants: any[];
  refetchTasks: () => void;
}

const FILE_TYPE_OPTIONS = [
  {
    value: 'pdf',
    label: 'PDF',
  },
  {
    value: 'csv',
    label: 'CSV',
  },
  {
    value: 'vusion',
    label: 'Vusion',
  },
];

const CUSTOMER_TYPE_OPTIONS = {
  TRANSACTION: [
    {
      value: 'carrier',
      label: 'Carrier',
    },
    {
      value: 'program',
      label: 'Program',
    },
  ],
};

const JOB_TYPE_OPTIONS = [
  {
    value: 'transaction_report',
    label: 'Transaction Report',
  },
];

const TRANSPORT_OPTIONS = [
  {
    value: 'FILE_POSTING_TRANSPORT_EMAIL',
    label: 'Email',
  },
  {
    value: 'FILE_POSTING_TRANSPORT_SFTP_PUSH',
    label: 'FTP',
  },
  {
    value: 'FILE_POSTING_TRANSPORT_BOTH',
    label: 'Both',
  },
];

const FREQUENCY_OPTIONS = [
  {
    value: '24h',
    label: 'Daily',
  },
  {
    value: '168h',
    label: 'Weekly',
  },
];

const validationSchema = Yup.object().shape({
  customerType: Yup.string().required(),
  frequency: Yup.string().required(),
  transport: Yup.string().required(),
  carrierUuid: Yup.string().when('customerType', {
    is: 'carrier',
    then: Yup.string().required('Select a carrier'),
  }),
  emailConfig: Yup.object().when('transport', {
    is: 'FILE_POSTING_TRANSPORT_EMAIL',
    then: Yup.object({
      recipients: Yup.array().of(Yup.string().email()).min(1).required(),
    }),
  }),
  host: Yup.string().when('transport', {
    is: 'FILE_POSTING_TRANSPORT_SFTP_PUSH',
    then: Yup.string().required(),
  }),
  port: Yup.string().when('transport', {
    is: 'FILE_POSTING_TRANSPORT_SFTP_PUSH',
    then: Yup.string().required(),
  }),
  username: Yup.string().when('transport', {
    is: 'FILE_POSTING_TRANSPORT_SFTP_PUSH',
    then: Yup.string().required(),
  }),
  password: Yup.string().when('transport', {
    is: 'FILE_POSTING_TRANSPORT_SFTP_PUSH',
    then: Yup.string().required(),
  }),
  cutoffDatetime: Yup.string().when('jobName', {
    is: (jobName: string) => {
      return jobName !== 'locations_report';
    },
    then: Yup.string().required(),
  }),
  fileType: Yup.string().when('jobName', {
    is: (jobName: string) => {
      return jobName !== 'locations_report';
    },
    then: Yup.string().required(),
  }),
  confirmationEmail: Yup.string().when('generateInvoice', {
    is: 'true',
    then: Yup.string().required('confirmation email is required'),
  }),
});

const intialValues = {
  customerType: '',
  jobName: JOB_TYPE_OPTIONS[0].value,
  transport: '',
  frequency: '',
  emailConfig: {
    recipients: [],
  },
  cutoffDatetime: new Date(
    new Date().toLocaleString('en-US', { timeZone: 'EST' }),
  ).setHours(0, 0, 0, 0),
  scheduleDatetime: new Date(
    new Date().toLocaleString('en', { timeZone: 'EST' }),
  ),
  carrierUuid: '',
  productCategories: [],
  host: '',
  port: '',
  username: '',
  password: '',
  targetDir: '',
  publicKey: '',
  fileType: '',
  confirmationEmail: '',
};

const toEST = (dateTime: Date): Date => {
  let estOffset: number;
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  switch (timeZone) {
    case 'America/New_York':
      estOffset = 0;
      break;
    case 'America/Chicago':
      estOffset = 1;
      break;
    case 'America/Los_Angeles':
      estOffset = 3;
      break;
    case 'US/Pacific':
      estOffset = 3;
      break;
    default:
      estOffset = 0;
  }
  const estDatetime = subHours(dateTime, estOffset);
  return estDatetime;
};

const TaskSchedulerModal = ({
  carriers,
  handleClose,
  refetchTasks,
}: Iprops) => {
  const programUuid = auth.getProgramUuid();

  const handleScheduleTask = async (values: any) => {
    const headers = {
      'carrier-id': values.carrierUuid,
      'program-id': programUuid,
      'product-categories': values.productCategories
        ? values.productCategories.join()
        : '',
      'ftp-host': values.host,
      'ftp-port': values.port,
      'ftp-username': values.username,
      'ftp-password': values.password,
      'target-dir': values.targetDir,
      'ftp-ssh-public-key': values.publicKey
        ? base64_encode(values.publicKey)
        : '',
      'cutoff-datetime': toEST(values.cutoffDatetime),
      'delivery-method': values.transport,
      'email-recipients': values.emailConfig.recipients
        ? values.emailConfig.recipients.join()
        : '',
      'file-type': values.fileType,
      'confirmation-email': values.confirmationEmail,
    };

    const estScheduleTime = toEST(values.scheduleDatetime);

    const url: string = TaskType.getByCustomer(values.customerType)?.value;

    const taskPayload = {
      url: url,
      method: 'get',
      headers: headers,
      is_cron: true,
      schedule_time: estScheduleTime.toISOString(),
      execution_interval: values.frequency,
      created_for: programUuid ?? values.carrierUuid,
    };

    try {
      const response = await scheduleTask({
        values: taskPayload,
        programUuid: programUuid,
      });

      if (response.status === 200) {
        toast.success('Report scheduled successfully!');
      } else {
        toast.error(
          'Unable to schedule report. Contact support or try again later.',
        );
      }
    } catch (error) {
      toast.error(
        `Unable to schedule report. Please try again later. Error: ${error}`,
      );
    } finally {
      await refetchTasks();
    }
  };

  return (
    <Formik
      initialValues={intialValues}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnBlur={false}
      onSubmit={async (values) => handleScheduleTask(values).then(handleClose)}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => (
        <Dialog open onClose={handleClose}>
          <DialogTitle>Schedule Report</DialogTitle>
          <DialogContent>
            <Box>
              <FormControl fullWidth sx={{ mt: 1 }}>
                <TextField
                  select
                  label="Customer Type"
                  name="customerType"
                  value={values.customerType}
                  onChange={handleChange}
                  error={touched.customerType && !!errors.customerType}
                >
                  {CUSTOMER_TYPE_OPTIONS.TRANSACTION.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
              {values.customerType === 'carrier' && (
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <TextField
                    select
                    label="Carrier Name"
                    name="carrierUuid"
                    value={values.carrierUuid}
                    error={touched.carrierUuid && !!errors.carrierUuid}
                    onChange={handleChange}
                  >
                    {carriers.map((option) => (
                      <MenuItem key={option.uuid} value={option.uuid}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              )}
              <FormControl
                fullWidth
                sx={{ mt: 2 }}
                error={touched.frequency && !!errors.frequency}
              >
                <FormLabel error={touched.frequency && !!errors.frequency}>
                  Frequency
                </FormLabel>
                <RadioGroup
                  name="frequency"
                  value={values.frequency}
                  onChange={handleChange}
                  row
                >
                  {FREQUENCY_OPTIONS.map((x) => (
                    <FormControlLabel
                      key={x.label}
                      control={<Radio />}
                      value={x.value}
                      label={x.label}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
              {
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <FormLabel error={touched.fileType && !!errors.fileType}>
                    File Type
                  </FormLabel>
                  <RadioGroup
                    name="fileType"
                    value={values.fileType}
                    onChange={handleChange}
                    row
                  >
                    {FILE_TYPE_OPTIONS.map((x) => {
                      return (
                        <FormControlLabel
                          key={x.label}
                          control={<Radio />}
                          value={x.value}
                          label={x.label}
                        />
                      );
                    })}
                  </RadioGroup>
                </FormControl>
              }
              <FormControl fullWidth sx={{ mt: 2 }}>
                <FormLabel error={touched.transport && !!errors.transport}>
                  Delivery
                </FormLabel>
                <RadioGroup
                  name="transport"
                  value={values.transport}
                  onChange={handleChange}
                  row
                >
                  {TRANSPORT_OPTIONS.map((x) => {
                    if (
                      x.value == 'FILE_POSTING_TRANSPORT_BOTH' &&
                      values.customerType != 'merchant'
                    ) {
                      return;
                    }
                    return (
                      <FormControlLabel
                        key={x.label}
                        control={<Radio />}
                        value={x.value}
                        label={x.label}
                      />
                    );
                  })}
                </RadioGroup>
              </FormControl>
              {(values.transport === 'FILE_POSTING_TRANSPORT_EMAIL' ||
                (values.transport === 'FILE_POSTING_TRANSPORT_BOTH' &&
                  values.customerType == 'merchant')) && (
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <Autocomplete
                    multiple
                    freeSolo
                    id="recipients"
                    options={[]}
                    value={values.emailConfig.recipients}
                    onChange={(_, newValue) => {
                      setFieldValue('emailConfig.recipients', newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Email Recipients"
                        error={
                          touched.emailConfig?.recipients?.length === 0 &&
                          !!errors.emailConfig?.recipients
                        }
                        helperText="Press Enter to add to recipients list"
                      />
                    )}
                  />
                </FormControl>
              )}
              {(values.transport === 'FILE_POSTING_TRANSPORT_SFTP_PUSH' ||
                (values.transport === 'FILE_POSTING_TRANSPORT_BOTH' &&
                  values.customerType == 'merchant')) && (
                <>
                  <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
                    <TextField
                      id="ftp-host"
                      name="host"
                      placeholder="e.g. roadranger.hostedftp.com"
                      label="Host"
                      value={values.host}
                      onChange={handleChange}
                      error={touched.host && !!errors.host}
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <TextField
                      id="ftp-port"
                      name="port"
                      placeholder="e.g. 21"
                      label="Port"
                      value={values.port}
                      onChange={handleChange}
                      error={touched.port && !!errors.port}
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <TextField
                      id="ftp-username"
                      name="username"
                      label="Username"
                      value={values.username}
                      onChange={handleChange}
                      error={touched.username && !!errors.username}
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <TextField
                      id="ftp-password"
                      name="password"
                      type="password"
                      label="Password"
                      value={values.password}
                      onChange={handleChange}
                      error={touched.password && !!errors.password}
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <TextField
                      id="target-directory"
                      name="directory"
                      placeholder="e.g. ONRAMP"
                      label="Target Directory (optional)"
                      value={values.targetDir}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <TextField
                      id="ssh-public-key"
                      name="ssh-public-key"
                      label="Public Key (optional)"
                      placeholder="~/.ssh/known_hosts format e.g. roadranger.hostedftp.com ssh-rsa AAAAB3NzaC1yc2EAA..."
                      value={values.publicKey}
                      onChange={handleChange}
                    />
                  </FormControl>
                </>
              )}
              <Box display="flex" flexDirection="row">
                <FormControl fullWidth sx={{ mt: 4, mr: 1 }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                      label="Schedule Datetime"
                      disablePast
                      value={values.scheduleDatetime}
                      onChange={(newValue) => {
                        setFieldValue('scheduleDatetime', newValue);
                      }}
                      closeOnSelect={false}
                      PopperProps={{
                        placement: 'top',
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(
                            errors && getIn(errors, 'scheduleDatetime'),
                          )}
                          helperText="Schedule Time is displayed as EST"
                        />
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
                <FormControl fullWidth sx={{ mt: 4 }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopTimePicker
                      label="Cutoff Time"
                      value={values.cutoffDatetime}
                      onChange={(newValue) => {
                        setFieldValue('cutoffDatetime', newValue);
                      }}
                      closeOnSelect={false}
                      PopperProps={{
                        placement: 'top',
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(
                            errors && getIn(errors, 'cutoffDatetime'),
                          )}
                          helperText="Cutoff Time is displayed as EST"
                        />
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <SecondaryButton onClick={handleClose}>Cancel</SecondaryButton>
            <PrimaryButton onClick={handleSubmit}>Save</PrimaryButton>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
};

export default TaskSchedulerModal;
