import { parseISO } from 'date-fns';
import { useEffect, useReducer } from 'react';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { Box, Chip, Tooltip } from '@mui/material';
import toast from 'react-hot-toast';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { formatInTimeZone } from 'date-fns-tz';
import { PrimaryButton } from '@/aggregator/ui/inputs/Button';
import useMerchants from '@/aggregator/hooks/useMerchants';
import TaskSchedulerModal from './TaskSchedulerModal';
import {
  cancelTask,
  dispatchTask,
  listScheduledTasks,
} from '@/aggregator/services/shared';
import { useQuery } from '@apollo/client';
import * as queries from '../../private/carriers/queries';
import * as types from '../../private/carriers/types';
import * as auth from '@/aggregator/config/auth';
import { Table } from '@/aggregator/ui/data-display/Table';
import { ActionModal } from './ActionModal';

const initialState = {
  scheduleConfigs: [],
  loading: true,
  openModal: false,
  openActionModal: false,
  task: { id: '', url: '' },
  tasks: [],
  rows: [],
  actionType: '',
};

function reducer(state: any, action: { type: string; payload: any }) {
  switch (action.type) {
    case 'setLoading':
      return { ...state, loading: action.payload };
    case 'setTasks':
      return { ...state, tasks: action.payload };
    case 'setRows':
      return { ...state, rows: action.payload };
    case 'setOpenModal':
      return { ...state, openModal: action.payload };
    case 'setOpenDispatchTaskModal':
      return { ...state, openDispatchTaskModal: action.payload };
    case 'setTask':
      return { ...state, task: action.payload };
    case 'setOpenCancelTaskModal':
      return { ...state, openCancelTaskModal: action.payload };
    case 'setOpenActionModal':
      return { ...state, openActionModal: action.payload };
    case 'setActionType':
      return { ...state, actionType: action.payload };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

export default function TaskScheduler() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const programUuid = auth.getProgramUuid();
  const { merchants } = useMerchants();
  const { data } = useQuery<{ getCarriers: types.CarrierType[] }>(
    queries.QUERY_GET_CARRIERS,
    {
      variables: { programUuid },
    },
  );

  const carriers = data?.getCarriers;

  // Helper function to fetch tasks
  const refetchTasks = async () => {
    try {
      dispatch({ type: 'setLoading', payload: true });
      const response = await listScheduledTasks({ programUuid });
      const tasks = response.data.tasks || [];
      const rows = tasks
        .filter((task: any) => task.headers) // Ensure valid tasks
        .map((task: any) => ({ task }));
      dispatch({ type: 'setTasks', payload: tasks });
      dispatch({ type: 'setRows', payload: rows });
    } catch (error) {
      console.error('Error fetching tasks:', error);
    } finally {
      dispatch({ type: 'setLoading', payload: false });
    }
  };

  const handleDispatchTask = async () => {
    try {
      const response = await dispatchTask(state.task.id);
      if (response.status === 200) {
        toast.success('Report dispatched successfully!');
        handleCloseActionModal();
        await refetchTasks();
      } else {
        toast.error(
          'Unable to dispatch report. Contact support for assistance or try again later.',
        );
      }
    } catch (error) {
      toast.error(
        `Unable to dispatch report. Please try again later. Error: ${error}`,
      );
    }
  };

  const handleCancelTask = async () => {
    try {
      const response = await cancelTask(state.task.id);
      if (response.status === 200) {
        toast.success('Report deleted successfully!');
        handleCloseActionModal();
        await refetchTasks();
      } else {
        toast.error(
          'Unable to delete report. Contact support for assistance or try again later.',
        );
      }
    } catch (error) {
      toast.error(
        `Unable to delete report. Please try again later. Error: ${error}`,
      );
    }
  };

  const handleOpenActionModal = (
    task: { id: string; url: string },
    action: 'cancel' | 'dispatch',
  ) => {
    dispatch({ type: 'setTask', payload: task });
    dispatch({ type: 'setActionType', payload: action });
    dispatch({ type: 'setOpenActionModal', payload: true });
  };

  const handleCloseActionModal = () => {
    dispatch({ type: 'setTask', payload: { id: '', url: '' } });
    dispatch({ type: 'setActionType', payload: '' });
    dispatch({ type: 'setOpenActionModal', payload: false });
  };

  useEffect(() => {
    if (programUuid) refetchTasks();
  }, [programUuid]);

  const columns: MUIDataTableColumnDef[] = [
    {
      label: 'Report Type',
      name: 'task.headers.carrier-id',
      options: {
        customBodyRender: (row) => (row ? 'Carrier' : 'Program'),
      },
    },
    {
      label: 'Carrier',
      name: 'task.headers.carrier-id',
      options: {
        customBodyRender: (row) =>
          carriers?.find((c) => c.uuid === row)?.name || '-',
      },
    },
    {
      label: 'Delivery',
      name: 'task.headers.delivery-method',
      options: {
        customBodyRender: (row) => {
          if (row === 'FILE_POSTING_TRANSPORT_BOTH') {
            return 'Email & SFTP';
          }
          return row === 'FILE_POSTING_TRANSPORT_EMAIL' ? 'Email' : 'SFTP';
        },
      },
    },
    {
      label: 'File Type',
      name: 'task.headers.file-type',
      options: {
        customBodyRender: (row) => {
          const text = row?.toUpperCase();
          if (!text) return '-';
          return text;
        },
      },
    },
    {
      label: 'Frequency',
      name: 'task.executionInterval',
      options: {
        customBodyRender: (row) => (row === '24h' ? 'Daily' : 'Weekly'),
      },
    },
    {
      label: 'Recipients',
      name: 'task',
      options: {
        sort: false,
        filter: false,
        searchable: false,
        setCellHeaderProps: () => ({
          style: {
            paddingLeft: 8,
          },
        }),
        customBodyRender: (task) => {
          let recipients: string[] = [];
          const appendEmailRecipients = () => {
            const emailRecipients = task.headers['email-recipients'];
            if (emailRecipients) {
              recipients = emailRecipients.split(',');
            }
          };

          const appendFtpRecipients = () => {
            const ftpRecipients = task.headers['ftp-host'];
            if (ftpRecipients) {
              recipients = ftpRecipients.split(',');
            }
          };

          switch (task.headers['delivery-method']) {
            case 'FILE_POSTING_TRANSPORT_BOTH':
              appendEmailRecipients();
              appendFtpRecipients();
              break;
            case 'FILE_POSTING_TRANSPORT_EMAIL':
              appendEmailRecipients();
              break;
            case 'FILE_POSTING_TRANSPORT_SFTP_PUSH':
              appendFtpRecipients();
              break;
          }

          switch (recipients.length) {
            case 0:
              return <>-</>;
            case 1:
              return (
                <Box
                  justifyContent="flex-start"
                  ml={-1}
                  maxWidth={300}
                  textOverflow="ellipsis"
                  overflow="hidden"
                >
                  {recipients}
                </Box>
              );
            default:
              const count = recipients.length;
              return (
                <Box justifyContent="flex-start" ml={-1}>
                  <Tooltip
                    title={recipients.map((x: any, idx: number) => (
                      <div key={idx}>{x}</div>
                    ))}
                  >
                    <Chip label={`${count} recipient${count > 1 ? 's' : ''}`} />
                  </Tooltip>
                </Box>
              );
          }
        },
      },
    },
    {
      label: 'Schedule Time',
      name: 'task.headers.schedule-time',
      options: {
        customBodyRender: (row) => {
          return `${formatInTimeZone(
            parseISO(row),
            'America/New_York',
            'MM/dd/yyyy - p (zzz)',
          ).replace('EDT', 'EST')}`;
        },
      },
    },
    {
      label: 'Cutoff Time',
      name: 'task.headers.cutoff-datetime',
      options: {
        customBodyRender: (row) => {
          return `${formatInTimeZone(
            parseISO(row),
            'America/New_York',
            'p (zzz)',
          ).replace('EDT', 'EST')}`;
        },
      },
    },
    {
      label: ' ',
      name: 'task',
      options: {
        sort: false,
        filter: false,
        viewColumns: false,
        customBodyRender: (task) => (
          <Tooltip title="Run Report">
            <IconButton
              onClick={() => handleOpenActionModal(task, 'dispatch')}
              disabled={state.loading}
            >
              <PlayArrowIcon />
            </IconButton>
          </Tooltip>
        ),
      },
    },
    {
      label: ' ',
      name: 'task',
      options: {
        sort: false,
        filter: false,
        viewColumns: false,
        customBodyRender: (task) => (
          <Tooltip title="Delete Report">
            <IconButton
              onClick={() => handleOpenActionModal(task, 'cancel')}
              disabled={state.loading}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ),
      },
    },
  ];

  return (
    <Box p={5}>
      <Box mb={2} display="flex" justifyContent="flex-end">
        <PrimaryButton
          onClick={() => dispatch({ type: 'setOpenModal', payload: true })}
        >
          Add Scheduled Report
        </PrimaryButton>
      </Box>
      <Table
        title="Scheduled Reports"
        data={state.rows}
        columns={columns}
        options={{
          pagination: true,
          rowsPerPage: 10,
          rowsPerPageOptions: [10, 20, 50],
          sortOrder: {
            name: 'task.headers.schedule-time',
            direction: 'desc',
          },
          search: true,
        }}
      />

      {state.openModal && (
        <TaskSchedulerModal
          merchants={merchants}
          carriers={carriers || []}
          handleClose={() => {
            dispatch({ type: 'setOpenModal', payload: false });
          }}
          refetchTasks={refetchTasks}
        />
      )}
      {state.openActionModal && (
        <ActionModal
          action={state.actionType}
          task={state.task}
          handleClose={handleCloseActionModal}
          handleDispatch={handleDispatchTask}
          handleCancel={handleCancelTask}
        />
      )}
    </Box>
  );
}
